import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { data } from '../constants/avatar';

const Skills = () => {
  const darkTheme = 'dark-theme';
  const iconTheme = 'ri-sun-line';

  const [theme, setTheme] = useState(
    localStorage.getItem('selected-theme') || 'light'
  );
  const [icon, setIcon] = useState(
    localStorage.getItem('selected-icon') || 'ri-sun-line'
  );

  useEffect(() => {
    document.body.classList.toggle(darkTheme, theme === 'dark');
    const themeButton = document.getElementById('theme-button');
    themeButton.classList.toggle(iconTheme, icon === 'ri-moon-line');
  }, [theme, icon]);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    const newIcon = icon === 'ri-moon-line' ? 'ri-sun-line' : 'ri-moon-line';
    setTheme(newTheme);
    setIcon(newIcon);
    localStorage.setItem('selected-theme', newTheme);
    localStorage.setItem('selected-icon', newIcon);
  };

  return (
    <>
      <header className="profile container">
        <i
          onClick={toggleTheme}
          className="ri-moon-line change-theme"
          id="theme-button"
        ></i>

        <div className="profile__container grid">
          <div className="profile__data">
            <div className="profile__border">
              <div className="profile__perfil">
                {data.map((item) => (
                  <img src={item.imgURL} alt="avatar" />
                ))}
              </div>
            </div>

            <h2 className="profile__name">Geo</h2>
            <h3 className="profile__profession">(I-CREATE)</h3>
            <h3 className="profile__profession">Web developer</h3>

            <ul className="profile__social">
              <a
                href="https://linkedin.com/in/georges-pislaru-635495319"
                target="_blank"
                className="profile__social-link"
                rel="noopener noreferrer"
              >
                <i className="ri-linkedin-box-line"></i>
              </a>
              <a
                href="https://github.com/"
                target="_blank"
                className="profile__social-link"
                rel="noopener noreferrer"
              >
                <i className="ri-github-line"></i>
              </a>
            </ul>
          </div>

          <p className="desc">
            I love art and always try to show unique views to the audience
            through my design, <br /> I am somebody who like to listen to
            people, reaching goals, always positive.
          </p>

          <div className="profile__buttons">
            <a download="" href="assets/pdf/Gianell-Cv.pdf" className="button">
              Download CV <i className="ri-download-line"></i>
            </a>

            <div className="profile__buttons-small">
              <a
                href="https://api.whatsapp.com/send?phone=+32492509075&text=Hello, more information!"
                target="_blank"
                className="button button__small button__gray"
                rel="noopener noreferrer"
              >
                <i className="ri-whatsapp-line"></i>
              </a>

              <a
                href="https://m.me/power.rage.35"
                target="_blank"
                className="button button__small button__gray"
                rel="noopener noreferrer"
              >
                <i className="ri-messenger-line"></i>
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="filters container">
        <ul className="filters__content">
          <Link className="link" to="/">
            <button className="filters__button">Projects</button>
          </Link>
          <Link className="link" to="/skills">
            <button className="filters__button filter-tab-active">
              Skills
            </button>
          </Link>
        </ul>
      </div>

      <main id="skills">
        <div className="skills__content grid" data-content id="skills">
          <div className="skills__area">
            <h3 className="skills__title">Frontend Developer</h3>

            <div className="skills__box">
              <div className="skills__group">
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">HTML</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>

                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">CSS</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>

                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Tailwind CSS</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>
                  <div>
                    <h3 className="skills__name">Styled Components</h3>
                    <span className="skills__level">Basic</span>
                  </div>
                </div>
              </div>

              <div className="skills__group">
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">JavaScript</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">React</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Next</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>

                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Git</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Chat GPT</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="skills__area">
            <h3 className="skills__title">Backend Developer</h3>

            <div className="skills__box">
              <div className="skills__group">
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">PHP</h3>
                    <span className="skills__level">Basic</span>
                  </div>
                </div>

                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">MySQL</h3>
                    <span className="skills__level">Basic</span>
                  </div>
                </div>

                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Firebase</h3>
                    <span className="skills__level">Advanced</span>
                  </div>
                </div>
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Sanity</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>
              </div>

              <div className="skills__group">
                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Python</h3>
                    <span className="skills__level">Basic</span>
                  </div>
                </div>

                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Node Js</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>

                <div className="skills__data">
                  <i className="ri-checkbox-circle-line"></i>

                  <div>
                    <h3 className="skills__name">Mongo DB</h3>
                    <span className="skills__level">Intermediate</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Skills;

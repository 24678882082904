import './App.css';
import Main from './components/Main';
import { Route, Routes } from 'react-router-dom';
import Skills from './pages/Skills';
import React, { useEffect } from 'react';
import Animate from './components/Animate';

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      const main = document.querySelector('main');
      if (main) {
        main.style.opacity = 1;
        main.style.filter = 'blur(0px)';
      }
    }, 1000);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <main style={{ opacity: 0, filter: 'blur(10px)' }}>
      <Animate />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/skills" element={<Skills />} />
      </Routes>
      <footer className="footer container">
        <span className="footer__copy">
          &#169; I-CREATE. All rigths reserved
          <p>{new Date().getFullYear()}</p>
        </span>
      </footer>
    </main>
  );
}

export default App;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { data } from '../constants/avatar'; // Avatar data from constants
import sanityClient from '../lib/sanityClient'; // Sanity client

const Main = () => {
  const darkTheme = 'dark-theme';
  const iconTheme = 'ri-sun-line';

  const [theme, setTheme] = useState(
    localStorage.getItem('selected-theme') || 'light'
  );
  const [icon, setIcon] = useState(
    localStorage.getItem('selected-icon') || 'ri-sun-line'
  );

  const [posts, setPosts] = useState([]); // For project data from Sanity

  useEffect(() => {
    document.body.classList.toggle(darkTheme, theme === 'dark');
    const themeButton = document.getElementById('theme-button');
    themeButton.classList.toggle(iconTheme, icon === 'ri-moon-line');
  }, [theme, icon]);

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    const newIcon = icon === 'ri-moon-line' ? 'ri-sun-line' : 'ri-moon-line';
    setTheme(newTheme);
    setIcon(newIcon);
    localStorage.setItem('selected-theme', newTheme);
    localStorage.setItem('selected-icon', newIcon);
  };

  // Fetch project data from Sanity
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
          _id,
          title,
          mainImage{
            asset->{
              url
            }
          },
          description,
          url
        }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  return (
    <>
      <header className="profile container">
        <i
          onClick={toggleTheme}
          className="ri-moon-line change-theme"
          id="theme-button"
        ></i>

        <div className="profile__container grid">
          <div className="profile__data">
            <div className="profile__border">
              <div className="profile__perfil">
                {/* Use avatar data from constants */}
                {data.map((item, index) => (
                  <img key={index} src={item.imgURL} alt="Avatar" />
                ))}
              </div>
            </div>

            <h2 className="profile__name">Geo</h2>
            <h3 className="profile__profession">(I-CREATE)</h3>
            <h3 className="profile__profession">Web developer</h3>
            <hr className="hr" />
            <p className="desc">
              I love art, and I always try to show unique views to the audience
              through my design. <br /> I am someone who likes to listen to
              people, reach goals, and stay positive.
            </p>

            <ul className="profile__social">
              <a
                href="https://linkedin.com/in/georges-pislaru-635495319"
                target="_blank"
                className="profile__social-link"
                rel="noopener noreferrer"
              >
                <i className="ri-linkedin-box-line"></i>
              </a>
              <a
                href="https://github.com/"
                target="_blank"
                className="profile__social-link"
                rel="noopener noreferrer"
              >
                <i className="ri-github-line"></i>
              </a>
            </ul>
          </div>

          <div className="profile__buttons">
            <a download href="assets/Cv.pdf" className="button">
              Download CV <i className="ri-download-line"></i>
            </a>

            <div className="profile__buttons-small">
              <a
                href="https://api.whatsapp.com/send?phone=+32492509075&text=Hello, more information!"
                target="_blank"
                className="button button__small button__gray"
                rel="noopener noreferrer"
              >
                <i className="ri-whatsapp-line"></i>
              </a>

              <a
                href="https://m.me/power.rage.35"
                target="_blank"
                className="button button__small button__gray"
                rel="noopener noreferrer"
              >
                <i className="ri-messenger-line"></i>
              </a>
            </div>
          </div>
        </div>
      </header>

      <main className="main">
        <section className="filters container">
          <ul className="filters__content">
            <Link className="link" to="/">
              <button className="filters__button filter-tab-active">
                Projects
              </button>
            </Link>
            <Link className="link" to="/skills">
              <button className="filters__button">Skills</button>
            </Link>
          </ul>

          <div className="filters__sections">
            <div
              className="projects__content grid filters__active"
              data-content
              id="projects"
            >
              {/* Render projects fetched from Sanity */}
              {posts.map((post) => (
                <article key={post._id} className="projects__card">
                  {post.mainImage && (
                    <img
                      src={post.mainImage.asset.url}
                      alt={post.title}
                      className="projects__img"
                    />
                  )}

                  <div className="projects__modal">
                    <div>
                      <span className="projects__subtitle">{post.title}</span>
                      <h3 className="projects__title">{post.description}</h3>
                      <a
                        href={post.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="projects__button button button__small"
                      >
                        <i className="ri-link"></i>
                      </a>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Main;

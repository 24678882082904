import { createClient } from '@sanity/client';

const sanityClient = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  apiVersion: '2023-01-01',
  useCdn: true,
});

export default sanityClient;
